<app-header *ngIf="userLogged" [b2sContent]="b2sContent"></app-header>
<app-header-public *ngIf="!userLogged"></app-header-public>

<!-- Main Content -->
<main role="main" class="content__main">
  <!-- Begin Begin Page Content -->
  <div class="container pt-3">
    <!-- Title -->
    <div class="d-sm-flex align-items-center justify-content-between mb-2">
      <h1 class="font-weight-bold h3 mb-0 text-gray">Registro de Proveedores</h1>
    </div>
    <!-- End Title -->

    <!-- Breadcrumb -->
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/account-statements', 0]"><i class="fas fa-home"></i></a></li>
        <li class="breadcrumb-item active" aria-current="page">Registro de Proveedores</li>
      </ol>
    </nav>
    <!-- End Breadcrumb -->

    <!-- Content -->
    <div class="row">

      <!-- Header -->
      <div class="col-md-12">
        <div *ngIf="cmsData" class="banner">
          <app-advertisements *ngIf="cmsData.espacios[1]" [typeContent]="'espacios'" [positions]="1"
            [contentData]="cmsData"></app-advertisements>
        </div>
        <h4 class="font-weight-bold text-primary">Bienvenido al Registro de Proveedores</h4>
        <div *ngIf="cmsData" class="banner">
          <app-advertisements *ngIf="cmsData.espacios[2]" [typeContent]="'espacios'" [positions]="2"
            [contentData]="cmsData"></app-advertisements>
        </div>
      </div>
      <!-- End Header -->

      <!-- Form -->
      <div class="col-md-12 mb-5 order-md-1">
        <form class="form__default" name="form" #form="ngForm">
          <ul class="nav nav-pills tab__default mb-3">
            <li class="nav-item">
              <button class="nav-link btn btn-login" [ngClass]="{'active':!flagPanelHome}" name="pills-home-tab"
                (click)="setPanel('home')">
                1<span>. Información General de la Organización</span>
              </button>
            </li>
            <li class="nav-item">
              <button class="nav-link btn btn-login" [ngClass]="{'active':!flagPanelProfile}" name="pills-profile-tab"
                (click)="setPanel('profile')">
                2<span>. Información de Contacto Organizacional</span>
              </button>
            </li>
            <li class="nav-item">
              <button class="nav-link btn btn-login" [ngClass]="{'active':!flagPanelContact}" name="pills-contact-tab"
                (click)="setPanel('contact')">
                3<span>. Documentos Anexos al Registro</span>
              </button>
            </li>
          </ul>

          <div name="pills-tabContent">
            <div *ngIf="flagPanelHome" class="tab-pane" name="pills-home">
              <h5 class="font-weight-bold text-primary mb-3">1. Información General de la Organización</h5>
              <div class="row">
                <div class="col-md-6 mb-3">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="far fa-building icon-form"></i></span>
                    </div>
                    <input type="text" class="form-control" placeholder="Escribe la Razón Social *" maxlength="50"
                      [(ngModel)]="supplierModel.businessName" name="businessName" #businessName="ngModel" required
                      autofocus>

                  </div>
                  <div *ngIf="(businessName.touched && businessName.invalid) || !validateModel.businessName"
                    class="danger small">
                    <div *ngIf="supplierModel.businessName.length <= 0">La Razón Social es un campo Requerido</div>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="far fa-user icon-form"></i></span>
                    </div>
                    <input type="text" class="form-control" placeholder="Escribe Nombre Abreviado *" maxlength="50"
                      [(ngModel)]="supplierModel.shortName" name="shortName" #shortName="ngModel" required autofocus>
                  </div>
                  <div *ngIf="(shortName.touched && shortName.invalid) || (!validateModel.shortName )"
                    class="danger small">
                    <div *ngIf="supplierModel.shortName.length <= 0">El Nombre Abreviado es un campo Requerido</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 mb-3">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="far fa-id-card icon-form"></i></span>
                    </div>
                    <input type="text" appOnlyNumbers class="form-control" placeholder="Escribe NIT o ID Tributario *"
                      maxlength="15" [(ngModel)]="supplierModel.documentID" name="documentID" #documentID="ngModel"
                      required
                      (change)="validateNitSupplierRegister(supplierModel.documentID,supplierModel.documentIdVerify)"
                      autofocus>
                    <input appOnlyNumbers class="form-control col-4" placeholder="Digito Verificación *"
                      [(ngModel)]="supplierModel.documentIdVerify" name="documentIdVerify" #documentIdVerify="ngModel"
                      required [maxLength]="1"
                      (change)="validateNitSupplierRegister(supplierModel.documentID,supplierModel.documentIdVerify)"
                      autofocus>
                  </div>
                  <div *ngIf="(documentID.touched && documentID.invalid) || !validateModel.documentID"
                    class="danger small">
                    <div *ngIf="supplierModel.documentID.length <= 0">El NIT o ID Tributario es un campo Requerido</div>
                  </div>
                  <div
                    *ngIf="(documentIdVerify.touched && documentIdVerify.invalid ) || !validateModel.documentIdVerify"
                    class="danger small">
                    <div *ngIf="supplierModel.documentIdVerify.length <= 0">El Digito Verificación es un campo Requerido
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fas fa-file-invoice-dollar icon-form"></i></span>
                    </div>
                    <select class="custom-select" autofocus [(ngModel)]="supplierModel.taxRegime" name="taxRegime"
                      #taxRegime="ngModel" required>
                      <option value="">Elije un Regimen Triburario *</option>
                      <option *ngFor="let taxRegime of taxRegimes" value="{{taxRegime.id}}">{{taxRegime.name}}</option>
                    </select>
                  </div>
                  <div *ngIf="(taxRegime.touched && taxRegime.invalid) || !validateModel.taxRegime"
                    class="danger small">
                    <div *ngIf="supplierModel.taxRegime.length <= 0">El Regimen Triburario es un campo Requerido</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 mb-3">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="far fa-money-bill-alt icon-form"></i></span>
                    </div>
                    <select class="custom-select" autofocus [(ngModel)]="supplierModel.economicActivity"
                      name="economicActivity" #economicActivity="ngModel" required>
                      <option value="">Elije una Actividad Económica *</option>
                      <option *ngFor="let economicAct of economicActivities" value="{{economicAct.id}}">
                        {{economicAct.name}}</option>
                    </select>
                  </div>
                  <div *ngIf="(economicActivity.touched && economicActivity.invalid) || !validateModel.economicActivity"
                    class="danger small">
                    <div *ngIf="supplierModel.economicActivity.length <= 0">La Actividad Económica es un campo Requerido
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fas fa-globe icon-form"></i></span>
                    </div>
                    <input type="text" class="form-control" placeholder="Escribe el Sitio Web "
                      [(ngModel)]="supplierModel.webSite" name="webSite" #webSite="ngModel" required autofocus>
                  </div>
                  <!-- <div *ngIf="(webSite.touched && webSite.invalid) || !validateModel.webSite" class="danger small"> -->
                  <!-- <div *ngIf="supplierModel.webSite.length <= 0">El Sitio Web es un campo Requerido</div> -->
                  <!-- </div> -->
                </div>
                <!--<div class="col-md-6 mb-3">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fas fa-money-bill-alt icon-form"></i></span>
                    </div>
                    <select class="custom-select" autofocus
                            [disabled]="!supplierModel.economicActivity"
                            [(ngModel)]="supplierModel.economicSubactivity" name="economicSubactivity" #economicSubactivity="ngModel" required>
                      <option value="">Elije una Sub Actividad Económica *</option>
                        <option *ngFor="let economicSubAct of economicSubActivities" value="{{economicSubAct.id}}">{{economicSubAct.name}}</option>
                    </select>
                  </div>
                  <div *ngIf="(economicSubactivity.touched && economicSubactivity.invalid) || !validateModel.economicSubactivity" class="danger small">
                    <div *ngIf="supplierModel.economicSubactivity.length <= 0">La Sub Actividad Económica es un campo Requerido</div>
                  </div>
                </div>-->
              </div>
              <div class="row">
                <div class="col-md-6 mb-3">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="far fa-building icon-form"></i></span>
                    </div>
                    <select class="custom-select" autofocus [(ngModel)]="supplierModel.pyme" name="pyme" #pyme="ngModel"
                      required>
                      <option value="">MiPyme *</option>
                      <option value="SI">SI</option>
                      <option value="NO">NO</option>
                    </select>
                  </div>
                  <div *ngIf="(pyme.touched && pyme.invalid) || !validateModel.pyme" class="danger small">
                    <div *ngIf="supplierModel.pyme.length <= 0">MiPyme es un campo Requerido</div>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="far fa-comment-dots icon-form"></i></span>
                  </div>
                  <textarea class="form-control"
                    placeholder="Mencione en terminos generales los productos o servicios que comercializa"
                    [(ngModel)]="supplierModel.messageBusiness" name="messageBusiness" #messageBusiness="ngModel"
                    autofocus></textarea>
                </div>
              </div>
              <div class="duo-btn-movil">
                <a class="btn btn-primary float-right btn-movil btn-icon-split mb-3" (click)="setNextPanel('home')">
                  <span class="text">Siguiente</span>
                  <span class="icon text-white-50">
                    <i class="fas fa-caret-right"></i>
                  </span>
                </a>
              </div>
            </div>
            <div *ngIf="flagPanelProfile" class="tab-pane" name="pills-profile">
              <h5 class="font-weight-bold text-primary mb-3">2. Información de Contacto Organizacional</h5>
              <div class="row">
                <div class="col-md-6 mb-3">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="far fa-user icon-form"></i></span>
                    </div>
                    <input type="text" appOnlyLetters class="form-control" placeholder="Nombre(s) *" maxlength="20"
                      [(ngModel)]="supplierModel.contactName" name="contactName" #contactName="ngModel" required
                      autofocus>
                  </div>
                  <div *ngIf="(contactName.touched && contactName.invalid) || !validateModel.contactName"
                    class="danger small">
                    <div *ngIf="supplierModel.contactName.length <= 0">El Nombre(s) es un campo Requerido</div>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fas fa-user icon-form"></i></span>
                    </div>
                    <input type="text" appOnlyLetters class="form-control" placeholder="Apellido(s) * " maxlength="30"
                      [(ngModel)]="supplierModel.contactLastname" name="contactLastname" #contactLastname="ngModel"
                      required autofocus>
                  </div>
                  <div *ngIf="(contactLastname.touched && contactLastname.invalid) || !validateModel.contactLastname"
                    class="danger small">
                    <div *ngIf="supplierModel.contactLastname.length <= 0">El Apellido(s) es un campo Requerido</div>
                  </div>
                </div>

                <div class="col-md-6 mb-3">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="far fa-id-card icon-form"></i></span>
                    </div>
                    <select class="custom-select" autofocus [(ngModel)]="supplierModel.contactIdentificationType"
                      name="contactIdentificationType" #contactIdentificationType="ngModel" required>
                      <option value="">Elije un Tipo Identificación *</option>
                      <option *ngFor="let IdType of identificationsTypes" value="{{IdType.id}}">{{IdType.nombre}}
                      </option>
                    </select>
                  </div>
                  <div
                    *ngIf="(contactIdentificationType.touched && contactIdentificationType.invalid) || !validateModel.contactIdentificationType"
                    class="danger small">
                    <div *ngIf="supplierModel.contactIdentificationType.length <= 0">El Tipo Identificación es un campo
                      Requerido</div>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fas fa-id-card icon-form"></i></span>
                    </div>
                    <input type="text" appOnlyNumbers class="form-control" placeholder="Numero de Identificación *"
                      [disabled]="!supplierModel.contactIdentificationType"
                      (change)="validateContactIdentificationRegisterSupplier(supplierModel.contactIdentificationType,supplierModel.contactIdentification)"
                      [(ngModel)]="supplierModel.contactIdentification" name="contactIdentification"
                      #contactIdentification="ngModel" autofocus required>
                  </div>
                  <div
                    *ngIf="(contactIdentification.touched && contactIdentification.invalid) || !validateModel.contactIdentification"
                    class="danger small">
                    <div *ngIf="supplierModel.contactIdentification.length <= 0">El Numero de Identificación es un campo
                      Requerido</div>
                  </div>
                </div>

                <div class="col-md-6 mb-3">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="far fa-id-card icon-form"></i></span>
                    </div>
                    <input type="text" class="form-control" placeholder="Cargo" maxlength="30"
                      [(ngModel)]="supplierModel.contactCharge" name="contactCharge" #contactCharge="ngModel" autofocus>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fas fa-id-card icon-form"></i></span>
                    </div>
                    <input type="text" class="form-control" placeholder="Área" maxlength="30"
                      [(ngModel)]="supplierModel.contactArea" name="contactArea" #contactArea="ngModel" autofocus>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fas fa-mobile-alt icon-form"></i></span>
                    </div>
                    <input appOnlyNumbers class="form-control col-3" placeholder="Indicativo *" autofocus required
                      maxlength="5" [(ngModel)]="supplierModel.contactIndicativeTelephone"
                      name="contactIndicativeTelephone" #contactIndicativeTelephone="ngModel">
                    <input appOnlyNumbers class="form-control" placeholder="Teléfono Móvil *" autofocus required
                      maxlength="10" minlength="10" [(ngModel)]="supplierModel.contactTelephone" name="contactTelephone"
                      #contactTelephone="ngModel">
                  </div>
                  <div
                    *ngIf="(contactIndicativeTelephone.touched && contactIndicativeTelephone.invalid) || !validateModel.contactIndicativeTelephone"
                    class="danger small">
                    <div *ngIf="supplierModel.contactIndicativeTelephone.length <= 0">El Indicativo es un campo
                      Requerido</div>
                    <div *ngIf="supplierModel.contactIndicativeTelephone.length > 4">El Indicativo no puede ser mayor a
                      4 digitos</div>
                  </div>
                  <div *ngIf="(contactTelephone.touched && contactTelephone.invalid) || !validateModel.contactTelephone"
                    class="danger small">
                    <div *ngIf="supplierModel.contactTelephone.length <= 0">El Teléfono Móvil es un campo Requerido
                    </div>
                    <div
                      *ngIf="supplierModel.contactTelephone.length > 0 && supplierModel.contactTelephone.length != 10">
                      El Teléfono Móvil debe tener 10 digitos</div>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fas fa-mobile-alt icon-form"></i></span>
                    </div>
                    <input type="email" class="form-control" placeholder="Email de Contacto *" maxlength="50"
                      [(ngModel)]="supplierModel.contactEmail" name="contactEmail"
                      (change)="validateEmailContactSupplier(supplierModel.contactEmail)" #contactEmail="ngModel"
                      required email autofocus>
                  </div>
                  <div *ngIf="(contactEmail.touched && contactEmail.invalid) || !validateModel.contactEmail"
                    class="danger small">
                    <div *ngIf="supplierModel.contactEmail.length <= 0">El Email de Contacto es un campo Requerido</div>
                  </div>
                  <div *ngIf="contactEmail.touched && contactEmail.invalid" class="danger small">
                    <div *ngIf="contactEmail.errors.email">Ingrese un Email válido</div>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fas fa-map-marker-alt icon-form"></i></span>
                    </div>
                    <input class="form-control" placeholder="Dirección *" autofocus maxlength="40"
                      [(ngModel)]="supplierModel.contactAddress" name="contactAddress" #contactAddress="ngModel"
                      required>
                  </div>
                  <div *ngIf="(contactAddress.touched && contactAddress.invalid) || !validateModel.contactAddress"
                    class="danger small">
                    <div *ngIf="supplierModel.contactAddress.length <= 0">La Dirección de Contacto es un campo Requerido
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fas fa-city icon-form"></i></span>
                    </div>
                    <input type="text" appOnlyLetters class="form-control" placeholder="Ciudad *" maxlength="20"
                      [(ngModel)]="supplierModel.contactCity" name="contactCity" #contactCity="ngModel" required
                      autofocus>
                  </div>
                  <div *ngIf="(contactCity.touched && contactCity.invalid) || !validateModel.contactCity"
                    class="danger small">
                    <div *ngIf="supplierModel.contactCity.length <= 0">La Ciudad es un campo Requerido</div>
                  </div>
                </div>
                <div class="col-md-6 mb-3" *ngIf="showEmail">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="far fa-calendar-alt"></i></span>
                    </div>
                    <input type="text" class="form-control" placeholder="Fecha de nacimiento *"
                      [(ngModel)]="supplierModel.birthdate" #birthdate="ngModel" onfocus="(this.type='date')"
                      name="birthdate" required />
                  </div>
                  <div *ngIf="(birthdate.touched && birthdate.invalid) || !validateModel.birthdate"
                    class="danger small">
                    <div *ngIf="supplierModel.birthdate.length <= 0">La fecha de nacimiento es un campo Requerido</div>
                  </div>
                </div>
              </div>
              <div class="duo-btn-movil">
                <a class="btn btn-primary float-right btn-movil btn-icon-split mb-3" (click)="setNextPanel('profile')">
                  <span class="text">Siguiente</span>
                  <span class="icon text-white-50">
                    <i class="fas fa-caret-right"></i>
                  </span>
                </a>
                <a class="btn btn-primary float-right btn-movil btn-back btn-icon-split mb-3"
                  (click)="setPreviousPanel('profile')">
                  <span class="icon text-white-50">
                    <i class="fas fa-caret-left"></i>
                  </span>
                  <span class="text">Atrás</span>
                </a>
              </div>
            </div>
            <div *ngIf="flagPanelContact" class="tab-pane" name="pills-contact">
              <h5 class="font-weight-bold text-primary mb-3">3. Documentos Anexos al Registro</h5>
              <div class="row">
                <div class="col-md-6 mb-3">
                  <div class="input-group input-file-xsm">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="far fa-file-alt icon-form"></i></span>
                    </div>
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" id="inputGroupFile01" accept="application/pdf"
                        (change)="handleFileInput($event.target.files, 'fileCertificateCCV')">
                      <label class="custom-file-label" for="inputGroupFile01">
                        <span>{{selectLabelFileInput('Certificado de Camara de Comercio Vigente *',fileCertificateCCV)}}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="input-group input-file-xsm">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="far fa-file-alt icon-form"></i></span>
                    </div>
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" id="inputGroupFile02" accept="application/pdf"
                        (change)="handleFileInput($event.target.files, 'fileRUT')">
                      <label class="custom-file-label" for="inputGroupFile02" aria-describedby="inputGroupFileAddon02">
                        <span>{{selectLabelFileInput('Registro Único Tributario RUT *', fileRUT)}} </span>
                      </label>

                    </div>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="input-group input-file-xsm">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="far fa-file-alt icon-form"></i></span>
                    </div>
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" id="inputGroupFile03" accept="application/pdf"
                        (change)="handleFileInput($event.target.files, 'fileFinancialStatements')">
                      <label class="custom-file-label" for="inputGroupFile03" aria-describedby="inputGroupFileAddon03">
                        <span>{{selectLabelFileInput('Estados Financieros al 31 de Dic. del año anterior',
                          fileFinancialStatements)}} </span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="input-group input-file-xsm">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="far fa-file-alt icon-form"></i></span>
                    </div>
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" id="inputGroupFile04" accept="application/pdf"
                        (change)="handleFileInput($event.target.files, 'fileCertificateBank')">
                      <label class="custom-file-label" for="inputGroupFile04" aria-describedby="inputGroupFileAddon04">
                        <span>{{selectLabelFileInput('Certificación Bancaria *', fileCertificateBank)}} </span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="input-group input-file-xsm">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="far fa-file-alt icon-form"></i></span>
                    </div>
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" id="inputGroupFile05" accept="application/pdf"
                        (change)="handleFileInput($event.target.files, 'fileCommercialReference1')">
                      <label class="custom-file-label" for="inputGroupFile05" aria-describedby="inputGroupFileAddon05">
                        <span>{{selectLabelFileInput('Carta de Referencia Comercial 1', fileCommercialReference1)}}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="input-group input-file-xsm">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="far fa-file-alt icon-form"></i></span>
                    </div>
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" id="inputGroupFile06" accept="application/pdf"
                        (change)="handleFileInput($event.target.files, 'fileCommercialReference2')">
                      <label class="custom-file-label" for="inputGroupFile06" aria-describedby="inputGroupFileAddon06">
                        <span>{{selectLabelFileInput('Carta de Referencia Comercial 2', fileCommercialReference2)}}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="input-group input-file-xsm">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="far fa-file-alt icon-form"></i></span>
                    </div>
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" id="inputGroupFile07" accept="application/pdf"
                        (change)="handleFileInput($event.target.files, 'fileIdManager')">
                      <label class="custom-file-label" for="inputGroupFile07" aria-describedby="inputGroupFileAddon07">
                        <span>{{selectLabelFileInput('Cédula de Representante Legal *', fileIdManager)}} </span>
                      </label>

                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 mb-3">
                  <div class="input-group">
                    <re-captcha [(ngModel)]="supplierModel.captchaValue" name="captcha" #captcha="ngModel"></re-captcha>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="checkAcepto" name="checkAcepto" autofocus
                      [(ngModel)]="supplierModel.checkAcepto" #checkAcepto="ngModel">
                    <label class="form-check-label" for="checkAcepto"><a [routerLink]="['','terms']"
                        target="_blank">Acepto términos y condiciones</a></label>
                    <br>
                    <input type="checkbox" class="form-check-input" id="checkAutorizo" name="checkAutorizo" autofocus
                      [(ngModel)]="supplierModel.checkAutorizo" #checkAutorizo="ngModel">
                    <label class="form-check-label" for="checkAutorizo"><a [routerLink]="['','privacy']"
                        target="_blank">Autorizo el tratamiento de datos</a></label>
                  </div>
                </div>
              </div>
              <div class="duo-btn-movil">
                <button class="btn btn-primary float-right btn-movil btn-icon-split mb-3" (click)="onSubmit()">
                  <span class="text">Finalizar</span>
                  <span class="icon text-white-50">
                    <i class="fa fa-paper-plane"></i>
                  </span>
                </button>
                <a class="btn btn-primary float-right btn-movil btn-back btn-icon-split mb-3"
                  (click)="setPreviousPanel('contact')">
                  <span class="icon text-white-50">
                    <i class="fas fa-caret-left"></i>
                  </span>
                  <span class="text">Atrás</span>
                </a>
              </div>
            </div>
          </div>

          <div>
            <p class="text-justify">Los campos marcados con (*) son obligatorios</p>
          </div>
        </form>
      </div>
      <!-- End Form -->

    </div>
    <!-- End Content -->
    <div class="col-md-12">
      <div *ngIf="cmsData" class="banner">
        <app-advertisements *ngIf="cmsData.espacios[3]" [typeContent]="'espacios'" [positions]="3"
          [contentData]="cmsData"></app-advertisements>
      </div>
    </div>

  </div>
  <!-- End Begin Page Content -->
</main>
<!-- Main Content -->
<!-- <app-footer [b2sContent]="b2sContent"></app-footer> -->